import * as yup from "yup";
import { BANK_ACCOUNT_NUMBER_REGEX, TAX_ID_REG_EXP } from "constants/RegExp";

export const bankAccountSchema = yup.object().shape({
  bookBankImage: yup
    .string()
    .trim()
    .required("deeplePay.verifyBilling.bankingInfo.bankAccountImage.notValid.empty.label"),
  bankAccountName: yup.string().trim().required("planBilling.info.error.name.required"),
  bankAccountNo: yup
    .string()
    .trim()
    .required("deeplePay.verifyBilling.bankingInfo.accountNo.notValid.empty.label")
    .matches(BANK_ACCOUNT_NUMBER_REGEX, "deeplePay.verifyBilling.bankingInfo.accountNo.notValid.label"),
});

export const defaultPersonalInfoSchema = {
  documentVerificationCertificatePath: yup
    .string()
    .trim()
    .required("deeplePay.verifyBilling.bankingInfo.bankAccountImage.notValid.empty.label"),
  name: yup.string().trim().required("planBilling.info.error.name.required"),
  address: yup.string().trim().required("planBilling.info.error.address.required"),
  postalCode: yup.string().trim().required("planBilling.info.error.postalCode.required"),
  subDistrict: yup.string().trim().required("planBilling.info.error.subDistrict.required"),
  district: yup.string().trim().required("planBilling.info.error.district.required"),
  province: yup.string().trim().required("planBilling.info.error.province.required"),
  taxId: yup
    .string()
    .matches(TAX_ID_REG_EXP, "planBilling.info.error.taxId.format")
    .required("planBilling.info.error.taxId.required"),
  email: yup
    .string()
    .trim()
    .required("planBilling.info.error.email.required")
    .email("planBilling.info.error.email.format"),
};

export const PersonalInfoSchema = yup.object().shape({
  titleName: yup.string().trim().required("planBilling.info.error.titleName.required"),
  ...defaultPersonalInfoSchema,
});

export const CompanyInfoSchema = yup.object().shape(defaultPersonalInfoSchema);

export const selfWithIdSchema = yup.object().shape({
  selfWithIdImage: yup
    .string()
    .trim()
    .required("deeplePay.verifyBilling.selfWithId.notValid.empty.label"),
});

export const emailSchema = yup.string().email("Invalid email address");
