import React, { FC, SyntheticEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stepper, TextField } from "@material-ui/core";
import { useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useLazyQuery } from "@apollo/client";
import useDevice from "utils/hooks/useDevice";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";
import { Device } from "types/Device";
import Grid from "components/Grid";
import Button from "components/Button";
import { BillingType } from "types/Billing";
import { ImageUrl } from "types/Image";
import { Bank } from "types/Bank";
import { SUBMIT_KYC } from "graphql/deeplePay/mutation";
import { CHECK_KYC_BY_EMAIL_WITH_USER_ID } from "graphql/deeplePay/query";
import { notifyError } from "utils/notify";
import AccordionStepper from "components/AccordionStepper";
import Typography from "components/Typography";
import { get, isEmpty, omit } from "lodash";
import { KYCInformationType, KYCInfo, KYCVerificationInput } from "types/DeeplePay";
import COLORS from "constants/Colors";
import { SvgIcon } from "components/Icon";
import { IcInfo } from "components/SvgIcons";
import { TitleOptions } from "constants/information";
import { bankAccountSchema, CompanyInfoSchema, emailSchema, PersonalInfoSchema, selfWithIdSchema } from "./validateSchema";
import Success from "./Success";
import BankingDetail from "./BankingDetail";
import Confirmation from "./Confirmation";
import PersonalDetail from "./PersonalDetail";
import CheckKYCStatus from "./CheckKYCStatus";
import SelfWithIDImage from "./SelfWithIDImage";
import {
  ButtonWrapper,
  DetailWrapper,
  MobileFooter,
  MobileWrapper,
  Modal,
  ModalWrapper,
  StepItem,
  MobileDetail,
  KYCButton,
  StepperItem,
  PersonalInformationBox,
} from "./styled";
import AttachmentExample from "./AttachmentExample";

interface KYCPopupPropsType {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  refetchBilling: () => void;
  billing: BillingType;
}

export interface BankFormData {
  bank?: Bank;
  bookBankImage?: string;
  bankAccountName?: string;
  bankAccountNo?: string;
}

export type PersonalValidation = KYCInfo & {
  titleName?: string;
  email?: string;
};

export interface SelfWithIDData {
  selfWithIdImage?: string;
}

const KYCPopup: FC<KYCPopupPropsType> = ({ isOpen, onClose, projectId }) => {
  const { t } = useTranslation();
  const device = useDevice();
  const { currentUser } = useGetUserWithPermission(projectId);
  const userId = currentUser?.id || "";
  const userEmail = currentUser?.email || "";

  const isMobile = device === Device.MOBILE;
  const steps = [
    "deeplePay.verifyBilling.step.header.checkKYCStatus",
    "deeplePay.verifyBilling.step.header.attachBankAccount",
    "deeplePay.verifyBilling.step.header.personalDetail",
    "deeplePay.verifyBilling.step.header.selfWithID",
    "deeplePay.verifyBilling.step.header.confirmation",
  ];
  const initialPersonalDetail = {
    type: KYCInformationType.PERSONAL,
    documentVerificationCertificatePath: "",
    titleName: "",
    name: "",
    taxId: "",
    address: "",
    subDistrict: "",
    district: "",
    province: "",
    postalCode: "",
    email: "",
  };
  const [page, setPage] = useState(0);
  const [bookBankImage, setBookBankImage] = useState<string>();
  const [selfWithIdImage, setSelfWithIdImage] = useState<string>();
  const [selfWithIdImagePath, setSelfWithIdImagePath] = useState<string>();
  const [bank, setBank] = useState<string>();
  const [isDirty, setIsDirty] = useState(false);
  const [bankData, setBankData] = useState<BankFormData>();
  const [personalDetail, setPersonalDetail] = useState<PersonalValidation>(initialPersonalDetail);
  const [exampleType, setExampleType] = useState<"bankAccount" | "personal" | "business" | "selfWithId">();
  const [kycEmail, setKycEmail] = useState<string>("");
  const [kycEmailError, setKycEmailError] = useState<string>("");
  const [kycFound, setKycFound] = useState<boolean>(false);
  const [isLoadingCheck, setIsLoadingCheck] = useState<boolean>(false);
  const imagePath = useRef<string>();
  const [documentVerificationCertificatePublicUrl, setDocumentVerificationCertificatePublicUrl] = useState<string>();
  const {
    register,
    unregister,
    errors,
    handleSubmit,
    reset,
    formState: { dirtyFields, isSubmitting },
    control,
    clearErrors,
  } = useForm<BankFormData>({
    resolver: yupResolver(bankAccountSchema),
    reValidateMode: "onChange",
  });
  const {
    register: personalRegister,
    handleSubmit: handleSubmitPersonal,
    errors: personalErrors,
    setValue,
    control: personalControl,
    reset: resetPersonalData,
  } = useForm<PersonalValidation>({
    resolver: yupResolver(personalDetail.type === KYCInformationType.PERSONAL ? PersonalInfoSchema : CompanyInfoSchema),
  });

  const {
    register: selfWithIdRegister,
    handleSubmit: handleSubmitSelfWithId,
    errors: selfWithIdErrors,
    control: selfWithIdControl,
    reset: resetSelfWithIdData,
  } = useForm<SelfWithIDData>({
    resolver: yupResolver(selfWithIdSchema),
  });

  const isCheckKYCPage = page === 0;
  const isBankingPage = page === 1;
  const isPersonalPage = page === 2;
  const isSelfWithIdPage = page === 3;
  const isConfirmationPage = page === 4;
  const isSuccessPage = page === 5;
  const isValidatedBanking = (Object.keys(omit(dirtyFields, ["bank"])).length >= 3 && isEmpty(errors)) || isDirty;
  const [submitKYCData] = useMutation(SUBMIT_KYC, {
    onCompleted: () => setPage(5),
    onError: () => notifyError(t("BILLING.DEFAULT_ERROR")),
  });

  // tracking validation to image field
  const { field } = useController({
    name: "bookBankImage",
    control,
  });

  // tracking validation to image field
  const { field: bankField } = useController({
    name: "bank",
    control,
  });

  // tracking validation to personal attachmentimage field
  const { field: personalImageField } = useController({
    name: "documentVerificationCertificatePath",
    control: personalControl,
  });

  // tracking validation to self with ID image field
  const { field: selfWithIdImageField } = useController({
    name: "selfWithIdImage",
    control: selfWithIdControl,
  });

  const [checkKycByEmailWithUserId, { loading: checkingKyc }] = useLazyQuery(CHECK_KYC_BY_EMAIL_WITH_USER_ID, {
    onCompleted: (data) => {
      setIsLoadingCheck(false);
      if (data?.checkKycByEmailWithUserId) {
        const kycData = data.checkKycByEmailWithUserId.kycVerification;
        const { 
          firstPageOfBankAccountUrl, 
          documentVerificationCertificateUrl, 
          selfWithIdImageUrl 
        } = data.checkKycByEmailWithUserId.publicImage;
        setKycFound(true);

        setBankData({
          bank: kycData.bank,
          bookBankImage: kycData.firstPageOfBankAccountPath,
          bankAccountName: kycData.bankAccountName,
          bankAccountNo: kycData.bankAccountNo,
        });
        setBookBankImage(firstPageOfBankAccountUrl);

        setPersonalDetail({
          type: kycData.type,
          name: kycData.name,
          taxId: kycData.taxId,
          titleName: kycData.titleName,
          address: kycData.address,
          subDistrict: kycData.subDistrict,
          district: kycData.district,
          province: kycData.province,
          postalCode: kycData.postalCode,
          documentVerificationCertificatePath: kycData.documentVerificationCertificatePath,
          email: kycData.email,
        });
        setDocumentVerificationCertificatePublicUrl(documentVerificationCertificateUrl);

        setSelfWithIdImage(selfWithIdImageUrl);
        setSelfWithIdImagePath(kycData.selfWithIdImage);

      } else {
        setPage(1);
      }
    },
    onError: (error) => {
      setIsLoadingCheck(false);
      notifyError(error);
      setKycEmailError(t("deeplePay.verifyBilling.checkKYC.error.apiError"));
    },
  });

  const handleSetBankDetail = (field: keyof BankFormData, value: string) => {
    setBankData((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleSetKYCDetail = (field: keyof PersonalValidation, value: string | KYCInformationType) => {
    setPersonalDetail((prevState) => ({ ...prevState, [field]: value }));
    setValue(field, value);
  };

  const handleChangeImage = (type: "personal" | "bankAccount" | "selfWithId", image: ImageUrl[]) => {
    if (image.length) {
      const [{ publicUrl, filePath }] = image;


      if (type === "bankAccount") {
        setBookBankImage(publicUrl);
        imagePath.current = publicUrl;
        field.onChange(filePath);
        field.onBlur();
      } else if (type === "personal") {
        setPersonalDetail({ 
          ...personalDetail, 
          documentVerificationCertificatePath: filePath 
        });
        setDocumentVerificationCertificatePublicUrl(publicUrl);
        setValue("documentVerificationCertificatePath", filePath);
        imagePath.current = publicUrl;
        personalImageField.onChange(filePath);
        personalImageField.onBlur();
      } else if (type === "selfWithId") {
        setSelfWithIdImage(publicUrl);
        setSelfWithIdImagePath(filePath);
        selfWithIdImageField.onChange(filePath);
        selfWithIdImageField.onBlur();
      }
    }
  };

  const handleSelectBank = (e: SyntheticEvent) => {
    const value = get(e, "target.value") || "";
    setBank(value);
    bankField.onChange(value);
    bankField.onBlur();
  };

  const handleRemoveImage = (type: "personal" | "bankAccount" | "selfWithId") => {
    if (type === "bankAccount") {
      setBookBankImage("");
      field.onChange("");
      field.onBlur();
    } else if (type === "personal") {
      setPersonalDetail({ ...personalDetail, documentVerificationCertificatePath: "" });
      setDocumentVerificationCertificatePublicUrl("");
      setValue("documentVerificationCertificatePath", "");
      imagePath.current = "";
      personalImageField.onChange("");
      personalImageField.onBlur();
    } else if (type === "selfWithId") {
      setSelfWithIdImage("");
      setSelfWithIdImagePath("");
      selfWithIdImageField.onChange("");
      selfWithIdImageField.onBlur();
    }
  };

  const handleVerifyAndUse = () => {
    // Email validation
    if (!kycEmail) {
      setKycEmailError(t("deeplePay.verifyBilling.checkKYC.error.emailRequired"));
      return;
    }
    
    if (!emailSchema.isValidSync(kycEmail)) {
      setKycEmailError(t("deeplePay.verifyBilling.checkKYC.error.invalidEmail"));
      return;
    }
    
    // Call the API to check KYC by email
    checkKycByEmailWithUserId({ variables: { email: kycEmail, userId } });
  };

  const handleSubmitNextPage = (formData: BankFormData) => {
    setBankData(formData);
    setPage(2);
  };

  const handleSubmitPersonalData = () => {
    setPage(3);
  };

  const handleSubmitSelfWithIdData = () => {
    if (kycFound && selfWithIdImagePath && !selfWithIdImage) {
      setSelfWithIdImage(selfWithIdImagePath);
    }
    setPage(4);
  };

  const handleSkipKYCCheck = () => {
    setPage(1);
  };

  const handleSubmitKYC = async () => {
    if (
      bankData && 
      bankData?.bookBankImage && 
      bankData?.bankAccountName && 
      bankData?.bankAccountNo && 
      bankData?.bank && 
      selfWithIdImage
    ) {
      const input: KYCVerificationInput = {
        firstPageOfBankAccountPath: bankData?.bookBankImage,
        bankAccountName: bankData?.bankAccountName,
        bankAccountNo: bankData?.bankAccountNo,
        bank: bankData?.bank,
        ...personalDetail,
        postalCode: `${personalDetail.postalCode}`,
        titleName: personalDetail.type === KYCInformationType.PERSONAL ? personalDetail.titleName : undefined,
        documentVerificationCertificatePath: personalDetail.documentVerificationCertificatePath,
        selfWithIdImage: selfWithIdImagePath,
        email: personalDetail.email,
      };

      await submitKYCData({ variables: { projectId, kycVerificationInput: input } });
    }
  };

  const handleClose = () => {
    setPage(0);
    unregister(["bookBankImage", "bank", "bankAccountName", "bankAccountNo"]);
    clearErrors();
    reset();
    setBankData({});
    setBookBankImage("");
    setSelfWithIdImage("");
    onClose();
    setBank("");
    resetPersonalData();
    resetSelfWithIdData();
    setExampleType(undefined);
    setPersonalDetail(initialPersonalDetail);
    setKycEmail("");
    setKycEmailError("");
  };

  const handleBack = () => {
    if (exampleType) {
      setExampleType(undefined);
      return;
    }

    if (isConfirmationPage && selfWithIdImagePath) {
      selfWithIdImageField.onChange(selfWithIdImagePath);
      selfWithIdImageField.onBlur();
    }

    if (isSelfWithIdPage && personalDetail.documentVerificationCertificatePath) {
      personalImageField.onChange(personalDetail.documentVerificationCertificatePath);
      personalImageField.onBlur();
    }

    if (isPersonalPage && bankData?.bookBankImage) {
      field.onChange(bankData.bookBankImage);
      field.onBlur();
      setIsDirty(true);
    }

    setPage(page - 1);
  };

  const handleUseExistingKYC = () => {
    if (selfWithIdImagePath) {
      selfWithIdImageField.onChange(selfWithIdImagePath);
      selfWithIdImageField.onBlur();
    }
    
    if (personalDetail.documentVerificationCertificatePath) {
      personalImageField.onChange(personalDetail.documentVerificationCertificatePath);
      personalImageField.onBlur();
    }
    
    if (bankData?.bookBankImage) {
      field.onChange(bankData.bookBankImage);
      field.onBlur();
    }
    
    if (bankData?.bank) {
      bankField.onChange(bankData.bank);
      bankField.onBlur();
      setIsDirty(true);
    }
    
    setPage(4);
  };

  const handleCreateNewKYC = () => {
    setKycFound(false);
    
    unregister(["bookBankImage", "bank", "bankAccountName", "bankAccountNo"]);
    clearErrors();
    reset();
    setBankData({});
    setBookBankImage("");
    setIsDirty(false);
    setSelfWithIdImage("");
    setSelfWithIdImagePath("");
    setBank(Bank.SCB);
    resetPersonalData();
    resetSelfWithIdData();
    setExampleType(undefined);
    setPersonalDetail(initialPersonalDetail);
    
    handleSetKYCDetail("titleName", TitleOptions[0].value);
    handleSetKYCDetail("type", KYCInformationType.PERSONAL);
    bankField.onChange(Bank.SCB);
    bankField.onBlur();
    
    setPage(1);
  };

  useEffect(() => {
    setBank(Bank.SCB);
    handleSetKYCDetail("titleName", personalDetail?.titleName || TitleOptions[0].value);
    handleSetKYCDetail("type", personalDetail?.type || KYCInformationType.PERSONAL);
    bankField.onChange(Bank.SCB);
    bankField.onBlur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen && userEmail && userId) {
      setKycFound(false);
      setIsLoadingCheck(true);
      setKycEmail(userEmail);
      checkKycByEmailWithUserId({ variables: { email: userEmail, userId } });
    }
  }, [isOpen, userEmail, userId]);

  useEffect(() => {
    if (isSubmitting) {
      setIsDirty(true);
    }
  }, [isSubmitting]);

  const bankDetailProps = {
    projectId,
    errors,
    register,
    bookBankImage,
    handleRemoveImage,
    handleChangeImage,
    control,
    bankData,
    isMobile,
    handleSelectBank,
    bank,
    setExampleType,
    handleSetBankDetail,
  };

  const personalProps = {
    handleChangeImage,
    handleRemoveImage,
    handleSetKYCDetail,
    personalDetail,
    documentVerificationCertificatePublicUrl,
    isMobile,
    projectId,
    register: personalRegister,
    control: personalControl,
    errors: personalErrors,
    setExampleType,
  };

  const selfWithIdProps = {
    handleChangeImage,
    handleRemoveImage,
    selfWithIdImage,
    isMobile,
    projectId,
    register: selfWithIdRegister,
    control: selfWithIdControl,
    errors: selfWithIdErrors,
    setExampleType,
  };

  const checkKYCProps = {
    kycEmail,
    setKycEmail,
    kycEmailError,
    setKycEmailError,
    isMobile,
    handleVerifyAndUse,
    checkingKyc,
  };

  const getPageDetail = () => {
    if (exampleType) {
      return (
        <AttachmentExample
          type={exampleType}
          isMobile={isMobile}
          setIsOpenAttachmentExample={() => setExampleType(undefined)}
        />
      );
    }

    if (isCheckKYCPage) {
      if (kycFound) {
        return (
          <Grid container className={isMobile ? "p-4" : "px-5 py-3"}>
            <Grid style={{ textAlign: "center" }} item className="mt-2 w-100" justify="center">
              <Typography color={COLORS.Dark} variant="title2">
                {t("deeplePay.verifyBilling.checkKYC.existingKYC.title")}
              </Typography>
            </Grid>
            <Grid item xs={12} className="mt-3 mb-4">
              <Typography color={COLORS.DarkLight} variant="body3">
                {t("deeplePay.verifyBilling.checkKYC.existingKYC.description")}
              </Typography>
            </Grid>
            <Grid item xs={12} className="mb-3">
              <TextField
                fullWidth
                disabled
                value={userEmail}
                inputProps={{
                  style: { textAlign: 'center' }
                }}
              />
            </Grid>
            <Grid item xs={12} className="mb-3">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleUseExistingKYC}
              >
                {t("deeplePay.verifyBilling.button.verifyAndUse")}
              </Button>
            </Grid>
          </Grid>
        );
      }
      
      if (isLoadingCheck || checkingKyc) {
        return (
          <Grid container className={isMobile ? "p-4" : "px-5 py-3"} justify="center">
            <Grid item className="my-5">
              <Typography color={COLORS.DarkLight} variant="body3">
                {t("deeplePay.verifyBilling.checkKYC.loading")}
              </Typography>
            </Grid>
          </Grid>
        );
      }
      
      return <CheckKYCStatus {...checkKYCProps} />;
    }

    if (isPersonalPage) {
      return <PersonalDetail {...personalProps} />;
    }

    if (isSelfWithIdPage) {
      return <SelfWithIDImage {...selfWithIdProps} />;
    }

    if (isConfirmationPage) {
      return (
        <Confirmation 
          banking={{ ...bankData, bookBankImage }} 
          personalDetail={personalDetail}
          selfWithIdImage={selfWithIdImage}
          documentVerificationCertificatePublicUrl={documentVerificationCertificatePublicUrl}
          {...bankDetailProps} 
        />
      );
    }

    if (isSuccessPage) {
      return <Success />;
    }

    return <BankingDetail {...bankDetailProps} />;
  };

  const getHandleSubmit = () => {
    if (isCheckKYCPage) {
      return handleVerifyAndUse;
    }

    if (isPersonalPage) {
      return handleSubmitPersonal(handleSubmitPersonalData);
    }

    if (isSelfWithIdPage) {
      return handleSubmitSelfWithId(handleSubmitSelfWithIdData);
    }

    if (isConfirmationPage) {
      return handleSubmitKYC;
    }

    return handleSubmit(handleSubmitNextPage);
  };

  const renderButtons = () => {
    if (isSuccessPage) {
      return (
        <KYCButton className="ml-2" minWidth={120} size="small" onClick={handleClose}>
          {t("deeplePay.verifyBilling.button.done")}
        </KYCButton>
      );
    }

    if (isCheckKYCPage && !kycFound && !isLoadingCheck && !checkingKyc) {
      return (
        <>
          <KYCButton
            className="cancel"
            minWidth={120}
            size="small"
            onClick={handleClose}
          >
            {t("deeplePay.verifyBilling.button.cancel")}
          </KYCButton>
          <KYCButton
            className="ml-2"
            minWidth={120}
            size="small"
            onClick={handleSkipKYCCheck}
          >
            {t("deeplePay.verifyBilling.button.skip")}
          </KYCButton>
        </>
      );
    }
    
    if (isCheckKYCPage && (isLoadingCheck || kycFound || checkingKyc)) {
      return (
        <>
          <KYCButton
            className="cancel"
            minWidth={120}
            size="small"
            onClick={handleClose}
          >
            {t("deeplePay.verifyBilling.button.cancel")}
          </KYCButton>
          <KYCButton
            className="ml-2"
            minWidth={120}
            size="small"
            onClick={handleCreateNewKYC}
          >
            {t("deeplePay.verifyBilling.button.skip")}
          </KYCButton>
        </>
      );
    }
    
    // For other pages
    return (
      <>
        <KYCButton
          className="cancel"
          minWidth={120}
          size="small"
          onClick={isBankingPage && !exampleType ? handleClose : handleBack}
        >
          {isBankingPage && !exampleType
            ? t("deeplePay.verifyBilling.button.cancel")
            : t("deeplePay.verifyBilling.button.back")}
        </KYCButton>
        <KYCButton
          className="ml-2"
          minWidth={120}
          size="small"
          disabled={((!isValidatedBanking && isBankingPage) || exampleType)}
          onClick={getHandleSubmit()}
        >
          {isConfirmationPage
            ? t("deeplePay.verifyBilling.button.submit")
            : t("deeplePay.verifyBilling.button.next")}
        </KYCButton>
      </>
    );
  };

  return (
    <Modal fullScreen={isMobile} isOpen={isOpen} onClose={handleClose} title={t("deeplePay.verifyBilling.header")}>
      {!isMobile ? (
        <ModalWrapper container justify="center">
          <Grid item xs={12}>
            <Stepper className="p-0 mb-2" activeStep={page} alternativeLabel>
              {steps.map((label) => (
                <StepItem key={label}>
                  <StepperItem>
                    <Typography variant="title8">{t(label)}</Typography>
                  </StepperItem>
                </StepItem>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            {(isPersonalPage || isSelfWithIdPage) && (
              <PersonalInformationBox className="wordBreak">
                <Typography color={COLORS.Accent} variant="body3" className="d-flex flex-row align-items-center">
                  <SvgIcon component={IcInfo} htmlColor={COLORS.Accent} fontSize="large" className="mx-3 pb-2" />
                  {t(isPersonalPage 
                    ? "deeplePay.verifyBilling.personalInfo.description" 
                    : "deeplePay.verifyBilling.selfWithId.description")}
                </Typography>
              </PersonalInformationBox>
            )}
            <DetailWrapper className={(isPersonalPage || isSelfWithIdPage) ? "info" : ""}>{getPageDetail()}</DetailWrapper>
          </Grid>
          <ButtonWrapper className="mt-3">
            {renderButtons()}
          </ButtonWrapper>
        </ModalWrapper>
      ) : (
        <MobileDetail container>
          <MobileWrapper>
            <Grid item xs={12}>
              <AccordionStepper
                stepNumber={1}
                isShowIcon={false}
                completedChildren={page > 0}
              >
                <Typography variant={isCheckKYCPage ? "title4" : "title8"}>{t(steps[0])}</Typography>
              </AccordionStepper>
            </Grid>
            {isCheckKYCPage && <>{getPageDetail()}</>}
            <Grid item xs={12}>
              <AccordionStepper
                stepNumber={2}
                isShowIcon={false}
                completedChildren={page > 1}
              >
                <Typography variant={isBankingPage ? "title4" : "title8"}>{t(steps[1])}</Typography>
              </AccordionStepper>
            </Grid>
            {exampleType ? (
              <AttachmentExample
                type={exampleType}
                isMobile={isMobile}
                setIsOpenAttachmentExample={() => setExampleType(undefined)}
              />
            ) : (
              <>
                {isBankingPage && <BankingDetail {...bankDetailProps} />}
                <Grid item xs={12}>
                  <AccordionStepper
                    stepNumber={3}
                    isShowIcon={false}
                    completedChildren={page > 2}
                  >
                    <Typography variant={isPersonalPage ? "title4" : "title8"}>{t(steps[2])}</Typography>
                  </AccordionStepper>
                </Grid>
                {isPersonalPage && <PersonalDetail {...personalProps} />}
                <Grid item xs={12}>
                  <AccordionStepper
                    stepNumber={4}
                    isShowIcon={false}
                    completedChildren={page > 3}
                  >
                    <Typography variant={isSelfWithIdPage ? "title4" : "title8"}>{t(steps[3])}</Typography>
                  </AccordionStepper>
                </Grid>
                {isSelfWithIdPage && <SelfWithIDImage {...selfWithIdProps} />}
                <Grid item xs={12}>
                  <AccordionStepper 
                    stepNumber={5} 
                    isShowIcon={false} 
                    completedChildren={isSuccessPage}
                  >
                    <Typography variant={isConfirmationPage ? "title4" : "title8"}>{t(steps[4])}</Typography>
                  </AccordionStepper>
                </Grid>
                {isConfirmationPage && (
                  <Confirmation
                    banking={{ ...bankData, bookBankImage }}
                    personalDetail={personalDetail}
                    selfWithIdImage={selfWithIdImage}
                    documentVerificationCertificatePublicUrl={documentVerificationCertificatePublicUrl}
                    {...bankDetailProps}
                  />
                )}
                {isSuccessPage && <Success />}
              </>
            )}
          </MobileWrapper>
          <MobileFooter>
            <Grid container justify="center">
              <Grid item className="mt-3 mb-3">
                {renderButtons()}
              </Grid>
            </Grid>
          </MobileFooter>
        </MobileDetail>
      )}
    </Modal>
  );
};

export default KYCPopup;
