const en = {
  "deeplePay.title": "LeanBiz pay",
  "deeplePay.header.transaction": "Transaction",
  "deeplePay.header.shipmentTransactionReport": "Shipment Transaction",
  "deeplePay.header.paymentChannel": "Payment channel",
  "deeplePay.header.cod": "Cash on delivery (COD)",
  "deeplePay.header.invoice": "LeanBiz pay invoice",

  "deeplePay.feeInvoice.table.title": "Monthly Fee",
  "deeplePay.feeInvoice.table.header.month": "Month",
  "deeplePay.feeInvoice.table.header.amount": "Total amount",
  "deeplePay.feeInvoice.table.header.file": "File",
  "deeplePay.feeInvoice.pdf": "PDF",
  "deeplePay.feeInvoice.noAttachment": "No attachment",

  "deeplePay.footer.explainVAT":
    "All transactions via LeanBiz pay will be charged 7% VAT in addition to each payment channel fee.",
  "deeplePay.totalBalance.title": "Your balance",
  "deeplePay.accountVerification.title": "Account verification",
  "deeplePay.bankAccount.title": "Bank account",
  "deeplePay.requestWithdraw.button.label": "Request a withdrawal",
  "deeplePay.verifyStatus.verified.label": "Verified",
  "deeplePay.verifyStatus.pending.label": "Pending verification",
  "deeplePay.verifyStatus.unverified.label": "Unverified",
  "deeplePay.verifyStatus.rejected.label": "Rejected",
  "deeplePay.verifyAccount.verify.button.label": "Attach bank account",
  "deeplePay.verifyAccount.reVerify.button.label": "Re-attach bank account",

  "deeplePay.verifyBilling.step.header.checkKYCStatus": "Found Existing KYC information",
  "deeplePay.verifyBilling.step.header.selfWithID": "Self with ID image",
  "deeplePay.verifyBilling.checkKYC.title": "Found Existing KYC information",
  "deeplePay.verifyBilling.checkKYC.description": "Please click 'Use This email KYC information' to use existing KYC info or Click 'Skip' for start new KYC",
  "deeplePay.verifyBilling.checkKYC.placeholder": "enter e-mail",
  "deeplePay.verifyBilling.checkKYC.instruction": "If you have KYC, you can use it. If not, press 'Skip' to continue KYC process.",
  "deeplePay.verifyBilling.checkKYC.error.emailRequired": "Please enter email",
  "deeplePay.verifyBilling.checkKYC.error.invalidEmail": "Please enter a valid email",
  "deeplePay.verifyBilling.checkKYC.error.emailNotFound": "Email not found. Please press \"Skip\" to continue KYC process",
  "deeplePay.verifyBilling.checkKYC.existingKYC.title": "KYC Information Found",
  "deeplePay.verifyBilling.checkKYC.existingKYC.description": "We found your existing KYC information. Would you like to use it?",
  "deeplePay.verifyBilling.checkKYC.loading": "Checking for existing KYC information...",
  "deeplePay.verifyBilling.button.createNew": "Create New KYC",

  "deeplePay.verifyBilling.selfWithId.title": "Self with ID image",
  "deeplePay.verifyBilling.selfWithId.description": "Please take a selfie photo while holding your ID card for verification purposes",
  "deeplePay.verifyBilling.selfWithId.instruction": "Take a clear photo of yourself holding your ID card. Make sure both your face and the ID card are clearly visible.",
  "deeplePay.verifyBilling.selfWithId.verified": "Verified",
  "deeplePay.verifyBilling.selfWithId.notValid.empty.label": "Please upload a selfie with your ID card",
  "deeplePay.verifyBilling.selfWithId.example.title": "Selfie with ID card sample",

  "deeplePay.verifyBilling.button.skip": "Skip",
  "deeplePay.verifyBilling.button.verifyAndUse": "Use This email KYC information",

  "withdrawalModal.title": "Withdrawal",
  "withdrawalModal.step1.title": "Enter amount",
  "withdrawalModal.step2.title": "Confirm info",
  "withdrawalModal.step1.contentTitle": "Enter amount",
  "withdrawalModal.step2.contentTitle": "Confirmation",
  "withdrawalModal.content.description": "Your money will be transferred to",
  "withdrawalModal.Amount": "Amount",
  "withdrawalModal.max": "Max",
  "withdrawalModal.Balance": "Balance: ",
  "withdrawalModal.Placeholder": "Enter amount ( min: ฿100, max: ฿700,000 )",
  "withdrawalModal.tooltip": "Please try to save some of your balance for future refunds.",
  "withdrawalModal.error.withdrawAmountExceedBalance": "The amount entered exceeds your balance.",
  "withdrawalModal.error.withdrawAmountExceedLimit": "The amount entered exceeds system limit",
  "withdrawalModal.error.withdrawAmountLessThanThreshold":
    "The amount entered is lower than the required minimum withdrawal",
  "withdrawalModal.Total": "Withdrawal amount",
  "withdrawalModal.Fee": "Fee",
  "withdrawalModal.TotalTransferred": "Total transferred",
  "withdrawalModal.success.description": "Your withdrawal has been successful.",
  "withdrawalModal.decline.title": "Transaction failed It is temporarily unavailable for some reason.",
  "withdrawalModal.decline.description": "Please wait a few minutes before you try again",
  "withdrawalModal.decline.contact": "Failed to try again, please contact LeanBiz support on channel.",
  "withdrawalModal.decline.contact.line": "Live-Chat, LINE OA (@deeplethailand)",
  "withdrawalModal.decline.contact.facebook": "Facebook Page (deeple Thailand)",
  "withdrawalModal.decline.contact.tel": "Call Center 02-460-9594",
  "withdrawalModal.decline.contact.other":
    "Business hours 8:00-24:00 every day, including holidays and public holidays",
  "deeplePay.verifyAccount.status.notVerify.label": "Please verify your store account by attaching your bank account.",
  "deeplePay.verifyBilling.error.title": "Your billing information is un-verified.",
  "deeplePay.verifyBilling.error.description":
    "Your ‘Billing information’ need to be verified before proceeding to account verification.",
  "deeplePay.verifyBilling.error.button.label": "Manage Billing",
  "deeplePay.verifyBilling.header": "Account verification",
  "deeplePay.verifyBilling.step.header.attachBankAccount": "Attach bank account",
  "deeplePay.verifyBilling.step.header.personalDetail": "Fill in personal details",
  "deeplePay.verifyBilling.step.header.confirmation": "Confirm info",
  "deeplePay.verifyBilling.button.next": "Next",
  "deeplePay.verifyBilling.button.back": "Back",
  "deeplePay.verifyBilling.button.cancel": "Cancel",
  "deeplePay.verifyBilling.button.submit": "Submit",
  "deeplePay.verifyBilling.button.done": "Done",
  "deeplePay.verifyBilling.confirmation.title": "Confirmation",
  "deeplePay.verifyBilling.success.description":
    "Information has been sent. We will keep you informed within 24 hours.",
  "deeplePay.verifyBilling.bankingInfo.title": "Bank account information",
  "deeplePay.verifyBilling.bankingInfo.description": "Please upload a picture of passbook or e-passbook. ",
  "deeplePay.verifyBilling.personalInfo.title": "Personal details",
  "deeplePay.verifyBilling.personalInfo.description":
    "Your ‘company name‘ or ‘full name’ must match your bank account name in your personal details for account verification.",
  "deeplePay.verifyBilling.personalInfo.individual.description": "Please upload a picture of your ID card. ",
  "deeplePay.verifyBilling.personalInfo.corporate.description": "Please upload a Business Registration Certificate. ",
  "deeplePay.verifyBilling.bankingInfo.seeExample": "See sample(s).",
  "deeplePay.verifyBilling.bankingInfo.example.title": "Passbook sample",
  "deeplePay.verifyBilling.personalInfo.individual.example.title": "ID card sample",
  "deeplePay.verifyBilling.personalInfo.corporate.example.title": "Business Registration Certificate sample",
  "deeplePay.verifyBilling.example.description": "You can upload either sample 1 or sample 2.",
  "deeplePay.verifyBilling.corporate.example.description": "You have to upload both certificates shown below.",
  "deeplePay.verifyBilling.bankingInfo.bankName": "Bank",
  "deeplePay.verifyBilling.bankingInfo.accountName": "Account name",
  "deeplePay.verifyBilling.bankingInfo.accountNo": "Account number",
  "deeplePay.verifyBilling.bankingInfo.accountNo.notValid.label": "Incorrect bank account number",
  "deeplePay.verifyBilling.bankingInfo.accountNo.notValid.empty.label": "Please fill in bank account number",
  "deeplePay.verifyBilling.bankingInfo.bankAccountImage.notValid.empty.label": "Please upload image",
  "deeplePay.verifyBilling.bankingInfo.accountName.notValid.label": "Please fill in bank account name",
  "deeplePay.verifyBilling.billingInfo.taxId.company": "Tax ID",
  "deeplePay.verifyBilling.billingInfo.taxId.personal": "Identification number",
  "deeplePay.transaction.export.label": "Export",
  "deeplePay.transaction.export.subLabel": "Format: CSV / XLSX",
  "deeplePay.transaction.export.success": "Export transactions are success",
  "deeplePay.transaction.table.header.transaction": "Transaction",
  "deeplePay.transaction.table.income.header.customerName": "Customer name",
  "deeplePay.transaction.table.income.header.paymentAmount": "Payment amount",
  "deeplePay.transaction.table.income.header.paymentChannel": "Payment channel",
  "deeplePay.transaction.table.income.header.createdDate": "Created date",
  "deeplePay.transaction.table.income.header.status": "Status",
  "deeplePay.transaction.table.withdrawal.header.email": "Email",
  "deeplePay.transaction.table.withdrawal.header.paymentAmount": "Payment amount",
  "deeplePay.transaction.table.withdrawal.header.destination": "Destination",
  "deeplePay.transaction.table.withdrawal.header.createdDate": "Created date",
  "deeplePay.transaction.table.withdrawal.header.status": "Status",
  "deeplePay.transaction.table.income.row.bank": "Bank",
  "deeplePay.transaction.table.income.row.receivedAmount": "Received Amount",
  "deeplePay.transaction.table.income.row.fee": "Fee",
  "deeplePay.transaction.table.income.row.referenceNo": "Reference no.",
  "deeplePay.transaction.table.income.row.refundDate": "Refund date",
  "deeplePay.transaction.table.income.row.refundStatus": "Refund status",
  "deeplePay.transaction.table.withdrawal.row.accountNo": "Account no.",
  "deeplePay.transaction.table.withdrawal.row.fee": "Fee",
  "deeplePay.transaction.table.income.row.paymentChannel": "Payment channel",
  "deeplePay.transaction.table.withdrawal.row.referenceNo": "Reference no.",
  "deeplePay.transaction.table.withdrawal.row.refundDate": "Refund date",
  "deeplePay.transaction.table.tooltip.vatIncluded": "(฿{{vat}}) 7% VAT included",
  "deeplePay.transaction.table.tooltip.receivedAmount": "The received amount excludes the fee charged per order.",

  "deeplePay.transaction.table.income.paymentChannel.title.trueMoneyWallet": "True Money wallet",
  "deeplePay.transaction.table.income.paymentChannel.title.kplus": "KPLUS",
  "deeplePay.transaction.table.income.paymentChannel.title.scb": "SCB easy",
  "deeplePay.transaction.table.income.paymentChannel.title.kma": "KMA",
  "deeplePay.transaction.table.income.paymentChannel.title.thaiQRPayment": "Thai QR payment",
  "deeplePay.transaction.table.income.paymentChannel.title.visa": "VISA",
  "deeplePay.transaction.table.income.paymentChannel.title.mastercard": "Mastercard",

  "deeplePay.transaction.table.income.paymentChannel.subtitle.eWallet": "E-wallet",
  "deeplePay.transaction.table.income.paymentChannel.subtitle.mobileBanking": "Mobile banking",
  "deeplePay.transaction.table.income.paymentChannel.subtitle.qrCode": "QR code",
  "deeplePay.transaction.table.income.paymentChannel.subtitle.creditCard": "Debit / credit card",

  "deeplePay.noDataFound": "No data found",

  "deeplePay.paymentChannel.minimum.unit": "baht",
  "deeplePay.paymentChannel.notification.label":
    "QR code is required to be ‘always active’ in order to proceed with the transactions of order less than ฿20.",
  "deeplePay.paymentChannel.qrCode.label": "QR Code",
  "deeplePay.paymentChannel.mobileBanking.label": "Mobile banking",
  "deeplePay.paymentChannel.mobileBanking.minimum":
    "Customers can use mobile banking to pay for orders that have a minimum total of",
  "deeplePay.paymentChannel.mobileBanking.description": "Only available for orders with the minimum of ฿20",
  "deeplePay.paymentChannel.mobileBanking.KBANK_MOBILE_BANKING.title": "KPLUS",
  "deeplePay.paymentChannel.mobileBanking.KRUGSRI_MOBILE_BANKING.title": "KMA",
  "deeplePay.paymentChannel.mobileBanking.SCB_MOBILE_BANKING.title": "SCB easy",
  "deeplePay.paymentChannel.creditCard.label": "Debit / Credit card",
  "deeplePay.paymentChannel.creditCard.minimum":
    "Customers can use debit/credit card to pay for orders that have a minimum total of",
  "deeplePay.paymentChannel.eWallet.label": "E-Wallet",
  "deeplePay.paymentChannel.eWallet.minimum":
    "Customers can use e-wallet to pay for orders that have a minimum total of",
  "deeplePay.paymentChannel.fee.min.label": "{{value}}% fee (฿{{min}} minimum)",
  "deeplePay.paymentChannel.fee.percent.label": "{{value}}% fee",

  "deeplePay.paymentChannel.qrCash.title": "Thai QR payment",
  "deeplePay.paymentChannel.qrCash.description": "Available via any mobile banking apps",
  "deeplePay.paymentChannel.qrCredit.title": "Visa QR payment",
  "deeplePay.paymentChannel.qrCredit.description": "Only available via SCB easy, KMA, CITIBANK and KTC Mobile",

  "deeplePay.paymentChannel.creditCard.VISA.title": "Visa",
  "deeplePay.paymentChannel.creditCard.MASTERCARD.title": "Mastercard",
  "deeplePay.paymentChannel.creditCard.JCB.title": "JCB",
  "deeplePay.paymentChannel.creditCard.VISA.description": "All visa card types supported",
  "deeplePay.paymentChannel.creditCard.MASTERCARD.description": "All mastercard card types supported",
  "deeplePay.paymentChannel.creditCard.JCB.description": "All JCB card types supported",

  "deeplePay.paymentChannel.trueMoneyWallet.title": "TrueMoney Wallet",
  "deeplePay.paymentChannel.trueMoneyWallet.description": "Payment completed via phone no. ",
  "deeplePay.paymentChannel.tab.title.income": "Income",
  "deeplePay.paymentChannel.tab.title.withdrawal": "Withdrawal",
  "deeplePay.transaction.table.processing": "processing",
  "deeplePay.transaction.table.completed": "completed",
  "deeplePay.transaction.table.voided": "voided",
  "deeplePay.transaction.table.declined": "declined",
  "deeplePay.transaction.table.created": "waiting",
  "deeplePay.transaction.table.withdrawn": "withdrawn",

  "deeplePay.transaction.table.refund.status.refund_pending": "processing",
  "deeplePay.transaction.table.refund.status.refunded": "refunded",
  "deeplePay.transaction.table.refund.status.refund_declined": "declined",

  "deeplePay.paymentChannel.openGuideline": "see more information about LeanBiz pay.",

  "deeplePay.updatePaymentConfig.error.INCORRECT_MINIMUM_AMOUNT": "incorrect minimum LeanBiz pay update amount",
  "deeplePay.updatePaymentConfig.error.INCORRECT_MOBILE_BANKING_MINIMUM_AMOUNT":
    "incorrect minimum mobile banking amount(at lease 20 baht)",
  "deeplePay.radio.description": "Automatic balance check",
  "deeplePay.details.seeExample": "See example",
  "deeplePay.details.description":
    "LeanBiz pay is a payment method from leanbiz.ai which supports direct payments from customers and connects to automated purchases.",
  "deeplePay.details.description2":
    "Payment methods via Thai QR Code payment, Debit / Credit card, Mobile Banking, including E-wallet.",
  "deeplePay.details.learnMore": "Learn more",
  "deeplePay.details.info":
    "Every transaction via LeanBiz pay will be charged 7% VAT on top of each payment channel fee.",
  "bankPayment.radio.title": "Bank transfer",
  "bankPayment.radio.description": "Admin checks payment confirmation",
  "bankPayment.details.description":
    "The money transfer slip is important information that the administrator must verify the correctness. and confirm payment The order will be changed to Paid/Pending Delivery. The system will automatically send a receipt to the customer.",
};

const th = {
  "deeplePay.title": "LeanBiz pay",
  "deeplePay.header.transaction": "รายการธุรกรรม",
  "deeplePay.header.shipmentTransactionReport": "รายการธุรกรรมการขนส่ง",
  "deeplePay.header.paymentChannel": "ช่องทางการชำระเงิน",
  "deeplePay.header.cod": "เก็บเงินปลายทาง (COD)",
  "deeplePay.header.invoice": "ใบเสร็จรับเงิน LeanBiz pay",
  "deeplePay.header.verificationSlip": "ยืนยันสลิปอัตโนมัติ",

  "deeplePay.feeInvoice.table.title": "ค่าธรรมเนียมรายเดือน",
  "deeplePay.feeInvoice.table.header.month": "เดือน",
  "deeplePay.feeInvoice.table.header.amount": "จำนวนเงิน",
  "deeplePay.feeInvoice.table.header.file": "ไฟล์",
  "deeplePay.feeInvoice.pdf": "PDF",
  "deeplePay.feeInvoice.noAttachment": "ไม่มีไฟล์",

  "deeplePay.footer.explainVAT":
    "ธุรกรรมทั้งหมดบน LeanBiz pay จะถูกเรียกเก็บภาษีมูลค่าเพิ่ม 7% นอกเหนือจากค่าธรรมเนียมของช่องทางการชำระเงินในแต่ละช่องทาง",
  "deeplePay.totalBalance.title": "ยอดเงินคงเหลือ",
  "deeplePay.accountVerification.title": "การตรวจสอบบัญชี",
  "deeplePay.bankAccount.title": "บัญชีธนาคาร",
  "deeplePay.requestWithdraw.button.label": "ถอนเงิน",
  "deeplePay.verifyStatus.verified.label": "ตรวจสอบแล้ว",
  "deeplePay.verifyStatus.pending.label": "อยู่ระหว่างการตรวจสอบ",
  "deeplePay.verifyStatus.unverified.label": "ไม่ได้รับการตรวจสอบ",
  "deeplePay.verifyStatus.rejected.label": "ถูกปฏิเสธ",
  "deeplePay.verifyAccount.status.notVerify.label": "โปรดยืนยันบัญชีร้านค้าของคุณ โดยบัญชีธนาคารของคุณที่แนบมา",
  "deeplePay.verifyAccount.verify.button.label": "ผูกบัญชีธนาคาร",
  "deeplePay.verifyAccount.reVerify.button.label": "ผูกบัญชีธนาคารอีกครั้ง",

  "deeplePay.verifyBilling.step.header.checkKYCStatus": "เราพบข้อมูล KYC ของคุณ",
  "deeplePay.verifyBilling.step.header.selfWithID": "ภาพถ่ายพร้อมบัตรประชาชน",
  "deeplePay.verifyBilling.checkKYC.title": "เราพบข้อมูล KYC ของคุณ",
  "deeplePay.verifyBilling.checkKYC.description": "ใส่ e-mail เพื่อตรวจสอบและใช้งาน ข้อมูล KYC ที่มีอยู่แล้ว",
  "deeplePay.verifyBilling.checkKYC.placeholder": "ใส่ e-mail เพื่อตรวจสอบ",
  "deeplePay.verifyBilling.checkKYC.instruction": "หากมี KYC แล้ว สามารถใช้ข้อมูลได้ แต่หากไม่มี กด 'ข้าม' เพื่อทำ KYC",
  "deeplePay.verifyBilling.checkKYC.error.emailRequired": "กรุณาใส่ email",
  "deeplePay.verifyBilling.checkKYC.error.invalidEmail": "กรุณาใส่ email ให้ถูกต้อง",
  "deeplePay.verifyBilling.checkKYC.error.emailNotFound": "ไม่พบ email กรุณา กด\"ข้าม\"เพื่อทำ KYC",
  "deeplePay.verifyBilling.checkKYC.existingKYC.title": "พบข้อมูล KYC",
  "deeplePay.verifyBilling.checkKYC.existingKYC.description": "กด 'ใช้ข้อมูล KYC ของ Email นี้' เพื่อใช้ข้อมูล KYC ที่มีอยู่แล้ว หรือกด 'ข้าม' เพื่อเริ่มต้น KYC ใหม่",
  "deeplePay.verifyBilling.checkKYC.loading": "กำลังตรวจสอบข้อมูล KYC ที่มีอยู่...",
  "deeplePay.verifyBilling.button.createNew": "ข้าม",

  "deeplePay.verifyBilling.selfWithId.title": "ภาพถ่ายพร้อมบัตรประชาชน",
  "deeplePay.verifyBilling.selfWithId.description": "กรุณาถ่ายภาพตัวเองพร้อมกับถือบัตรประชาชนเพื่อการยืนยันตัวตน",
  "deeplePay.verifyBilling.selfWithId.instruction": "ถ่ายภาพให้เห็นใบหน้าของคุณและบัตรประชาชนอย่างชัดเจน",
  "deeplePay.verifyBilling.selfWithId.verified": "ยืนยันแล้ว",
  "deeplePay.verifyBilling.selfWithId.notValid.empty.label": "กรุณาอัพโหลดภาพถ่ายพร้อมบัตรประชาชน",
  "deeplePay.verifyBilling.selfWithId.example.title": "ตัวอย่างภาพถ่ายพร้อมบัตรประชาชน",

  "deeplePay.verifyBilling.button.skip": "ข้าม",
  "deeplePay.verifyBilling.button.verifyAndUse": "ใช้ข้อมูล KYC ของ Email นี้",
  
  "withdrawalModal.title": "ถอนเงิน",
  "withdrawalModal.step1.title": "ระบุจำนวน",
  "withdrawalModal.step2.title": "ยืนยันข้อมูล",
  "withdrawalModal.step1.contentTitle": "ระบุจำนวน",
  "withdrawalModal.step2.contentTitle": "ยืนยันข้อมูล",
  "withdrawalModal.content.description": "เงินของคุณจะถูกโอนไปยัง",
  "withdrawalModal.Amount": "จำนวน",
  "withdrawalModal.max": "สูงสุด",
  "withdrawalModal.Balance": "ยอดเงินคงเหลือ: ",
  "withdrawalModal.Placeholder": "จำนวน ( ขั้นต่ำ: ฿100, สูงสุด: ฿700,000 )",
  "withdrawalModal.tooltip": "กรุณาพยายามเก็บยอดเงินคงเหลือบางส่วนไว้สำหรับการคืนเงินในอนาคต",
  "withdrawalModal.error.withdrawAmountExceedBalance": "จำนวนเงินที่คุณกรอกมีมากกว่ายอดเงินคงเหลือ",
  "withdrawalModal.error.withdrawAmountExceedLimit": "จำนวนเงินที่คุณกรอกมากกว่าระบบอนุญาตให้ถอน",
  "withdrawalModal.error.withdrawAmountLessThanThreshold": "จำนวนเงินที่คุณกรอกน้อยกว่าการถอนขั้นต่ำที่กำหนด",
  "withdrawalModal.Total": "จำนวนเงินที่ถอน",
  "withdrawalModal.Fee": "ค่าธรรมเนียม",
  "withdrawalModal.TotalTransferred": "ยอดโอนรวมทั้งสิ้น",
  "withdrawalModal.success.description": "ได้ทำการถอนเงินเรียบร้อยแล้ว",
  "withdrawalModal.decline.title": "ระบบไม่สามารถทำรายการได้ชั่วคราวด้วยเหตุผลบางประการ",
  "withdrawalModal.decline.description": "กรุณารอสักครู่ และลองทำรายการใหม่ภายหลัง",
  "withdrawalModal.decline.contact": " หากลองทำรายการใหม่อีกครั้งแล้วไม่สำเร็จ กรุณาติดต่อ LeanBiz Support ที่ช่องทาง",
  "withdrawalModal.decline.contact.line": "Live-Chat, LINE OA (@deeplethailand)",
  "withdrawalModal.decline.contact.facebook": "Facebook Page (deeple Thailand)",
  "withdrawalModal.decline.contact.tel": "Call Center เบอร์ 02-460-9595",
  "withdrawalModal.decline.contact.other": "ช่วงเวลาทำการ 8:00-24:00 น. ทุกวันไม่เว้นวันหยุดราชการและวันหยุดนักขัตฤกษ์",
  "deeplePay.verifyBilling.error.title": "ข้อมูลการเรียกเก็บเงินของคุณไม่ได้รับการยืนยัน",
  "deeplePay.verifyBilling.error.description":
    "`ข้อมูลการเรียกเก็บเงิน` ของคุณต้องได้รับการยืนยัน เพื่อให้สามารถดำเนินการตรวจสอบบัญชีได้",
  "deeplePay.verifyBilling.header": "การตรวจสอบบัญชี",
  "deeplePay.verifyBilling.error.button.label": "จัดการการเรียกเก็บเงิน",
  "deeplePay.verifyBilling.step.header.attachBankAccount": "ผูกบัญชีธนาคาร",
  "deeplePay.verifyBilling.step.header.personalDetail": "กรอกข้อมูลส่วนตัว",
  "deeplePay.verifyBilling.step.header.confirmation": "ยืนยันข้อมูล",
  "deeplePay.verifyBilling.button.next": "ถัดไป",
  "deeplePay.verifyBilling.button.back": "กลับ",
  "deeplePay.verifyBilling.button.cancel": "ยกเลิก",
  "deeplePay.verifyBilling.button.submit": "ยืนยัน",
  "deeplePay.verifyBilling.button.done": "เสร็จสิ้น",
  "deeplePay.verifyBilling.confirmation.title": "ยืนยันข้อมูล",
  "deeplePay.verifyBilling.success.description":
    "ส่งข้อมูลตรวจสอบบัญชีแล้ว เราจะรีบตรวจสอบและแจ้งผลให้คุณทราบภายใน 24 ชั่วโมง",
  "deeplePay.verifyBilling.bankingInfo.title": "ข้อมูลบัญชีธนาคาร",
  "deeplePay.verifyBilling.bankingInfo.description": "กรุณาอัพโหลดรูปภาพสมุดบัญชีเงินฝากหรือ e-passbook ",
  "deeplePay.verifyBilling.personalInfo.title": "ข้อมูลส่วนตัว",
  "deeplePay.verifyBilling.personalInfo.description":
    "'ชื่อบริษัท' หรือ 'ชื่อ-นามสกุล' ในรายละเอียดส่วนบุคคลจำเป็นต้องตรงกับชื่อบัญชีธนาคารของคุณเพื่อการยืนยันบัญชีร้านค้า",
  "deeplePay.verifyBilling.personalInfo.individual.description": "กรุณาอัพโหลดรูปภาพบัตรประชาชน ",
  "deeplePay.verifyBilling.personalInfo.corporate.description": "กรุณาอัพโหลดรูปภาพหนังสือรับรองการจดทะเบียนนิติบุคคล ",
  "deeplePay.verifyBilling.bankingInfo.seeExample": "ดูตัวอย่าง",
  "deeplePay.verifyBilling.bankingInfo.example.title": "ตัวอย่างสมุดบัญชี",
  "deeplePay.verifyBilling.personalInfo.individual.example.title": "ตัวอย่างบัตรประจำตัวประชาชน",
  "deeplePay.verifyBilling.personalInfo.corporate.example.title": "ตัวอย่างใบรับรองการจดทะเบียนนิติบุคคล",
  "deeplePay.verifyBilling.example.description": "คุณสามารถอัปโหลดตัวอย่างที่ 1 หรือตัวอย่างที่ 2",
  "deeplePay.verifyBilling.corporate.example.description": "คุณต้องอัปโหลดใบรับรองทั้งสองที่แสดงไว้ด้านล่าง",
  "deeplePay.verifyBilling.bankingInfo.bankName": "ธนาคาร",
  "deeplePay.verifyBilling.bankingInfo.accountName": "ชื่อบัญชี",
  "deeplePay.verifyBilling.bankingInfo.accountNo": "เลขที่บัญชี",
  "deeplePay.verifyBilling.bankingInfo.accountNo.notValid.label": "หมายเลขบัญชีไม่ถูกต้อง",
  "deeplePay.verifyBilling.bankingInfo.accountNo.notValid.empty.label": "กรุณากรอกเลขที่บัญชี",
  "deeplePay.verifyBilling.bankingInfo.bankAccountImage.notValid.empty.label": "กรุณาอัปโหลดภาพ",
  "deeplePay.verifyBilling.bankingInfo.accountName.notValid.label": "กรุณากรอกชื่อเจ้าของบัญชี",
  "deeplePay.verifyBilling.billingInfo.taxId.company": "หมายเลขประจำตัวผู้เสียภาษี",
  "deeplePay.verifyBilling.billingInfo.taxId.personal": "หมายเลขบัตรประชาชน",
  "deeplePay.transaction.export.label": "Export",
  "deeplePay.transaction.export.subLabel": "รูปแบบ: CSV / XLSX",
  "deeplePay.transaction.export.success": "นำออกข้อมูลรายการธุรกรรมสำเร็จ",
  "deeplePay.transaction.table.header.transaction": "รายการธุรกรรม",
  "deeplePay.transaction.table.income.header.customerName": "ชื่อลูกค้า",
  "deeplePay.transaction.table.income.header.paymentAmount": "จำนวนเงิน",
  "deeplePay.transaction.table.income.header.paymentChannel": "ช่องทางการจ่ายเงิน",
  "deeplePay.transaction.table.income.header.createdDate": "วันที่",
  "deeplePay.transaction.table.income.header.status": "สถานะ",
  "deeplePay.transaction.table.withdrawal.header.email": "อีเมล์",
  "deeplePay.transaction.table.withdrawal.header.paymentAmount": "จำนวนเงิน",
  "deeplePay.transaction.table.withdrawal.header.destination": "บัญชีปลายทาง",
  "deeplePay.transaction.table.withdrawal.header.createdDate": "วันที่",
  "deeplePay.transaction.table.withdrawal.header.status": "สถานะ",
  "deeplePay.transaction.table.income.row.bank": "ชื่อธนาคาร",
  "deeplePay.transaction.table.income.row.receivedAmount": "จำนวนเงินที่ได้รับ",
  "deeplePay.transaction.table.income.row.fee": "ค่าธรรมเนียม",
  "deeplePay.transaction.table.income.row.referenceNo": "Reference no.",
  "deeplePay.transaction.table.income.row.refundDate": "วันที่ทำรายการคืนเงิน",
  "deeplePay.transaction.table.income.row.refundStatus": "สถานะการคืนเงิน",
  "deeplePay.transaction.table.withdrawal.row.accountNo": "หมายเลขบัญชี",
  "deeplePay.transaction.table.withdrawal.row.fee": "ค่าธรรมเนียม",
  "deeplePay.transaction.table.income.row.paymentChannel": "ช่องทางชำระเงิน",
  "deeplePay.transaction.table.withdrawal.row.referenceNo": "Reference no.",
  "deeplePay.transaction.table.withdrawal.row.refundDate": "วันที่ทำรายการคืนเงิน",
  "deeplePay.transaction.table.tooltip.vatIncluded": "รวมภาษีมูลค่าเพิ่ม 7%(฿{{vat}})",
  "deeplePay.transaction.table.tooltip.receivedAmount":
    "จำนวนเงินที่ได้รับยังไม่รวมค่าธรรมเนียมที่เรียกเก็บต่อหนึ่งรายการสั่งซื้อ",

  "deeplePay.transaction.table.income.paymentChannel.title.trueMoneyWallet": "True Money wallet",
  "deeplePay.transaction.table.income.paymentChannel.title.kplus": "KPLUS",
  "deeplePay.transaction.table.income.paymentChannel.title.scb": "SCB easy",
  "deeplePay.transaction.table.income.paymentChannel.title.kma": "KMA",
  "deeplePay.transaction.table.income.paymentChannel.title.thaiQRPayment": "Thai QR payment",
  "deeplePay.transaction.table.income.paymentChannel.title.visa": "VISA",
  "deeplePay.transaction.table.income.paymentChannel.title.mastercard": "Mastercard",

  "deeplePay.transaction.table.income.paymentChannel.subtitle.eWallet": "อี-วอลเล็ต",
  "deeplePay.transaction.table.income.paymentChannel.subtitle.mobileBanking": "โมบายแบงก์กิ้ง",
  "deeplePay.transaction.table.income.paymentChannel.subtitle.qrCode": "คิวอาร์โค้ด",
  "deeplePay.transaction.table.income.paymentChannel.subtitle.creditCard": "บัตรเดบิต / เครดิต",

  "deeplePay.noDataFound": "ไม่พบข้อมูล",

  "deeplePay.paymentChannel.minimum.unit": "บาท",
  "deeplePay.paymentChannel.notification.label":
    "ช่องทางการชำระเงินผ่าน QR Code จะถูกเปิดใช้เสมอเพื่อให้ครอบคลุมการชำระเงินที่ต่ำกว่า 20 บาท ",
  "deeplePay.paymentChannel.qrCode.label": "คิวอาร์โค้ด",
  "deeplePay.paymentChannel.qrCash.label": "เงินสด",
  "deeplePay.paymentChannel.qrCredit.label": "คิวอาร์เครดิต",
  "deeplePay.paymentChannel.mobileBanking.label": "โมบายแบงก์กิ้ง",
  "deeplePay.paymentChannel.mobileBanking.minimum":
    "ลูกค้าจะชำระเงินผ่านโมบายแบงก์กิ้งได้ เมื่อมียอดสั่งซื้อขั้นต่ำอย่างน้อย",
  "deeplePay.paymentChannel.mobileBanking.description": "สำหรับการชำระเงินขั้นต่ำ 20 บาท",
  "deeplePay.paymentChannel.mobileBanking.KBANK_MOBILE_BANKING.title": "KPLUS",
  "deeplePay.paymentChannel.mobileBanking.KRUGSRI_MOBILE_BANKING.title": "KMA",
  "deeplePay.paymentChannel.mobileBanking.SCB_MOBILE_BANKING.title": "SCB easy",
  "deeplePay.paymentChannel.creditCard.label": "บัตรเดบิต / เครดิต",
  "deeplePay.paymentChannel.creditCard.minimum":
    "ลูกค้าจะชำระเงินผ่านบัตรเดบิต/เครดิตได้ เมื่อมียอดสั่งซื้อขั้นต่ำอย่างน้อย",
  "deeplePay.paymentChannel.eWallet.label": "E-Wallet",
  "deeplePay.paymentChannel.eWallet.minimum": "ลูกค้าจะชำระเงินผ่าน e-wallet ได้ เมื่อมียอดสั่งซื้อขั้นต่ำอย่างน้อย",
  "deeplePay.paymentChannel.fee.min.label": "ค่าธรรมเนียม {{value}}% (ขั้นต่ำ ฿{{min}})",
  "deeplePay.paymentChannel.fee.percent.label": "ค่าธรรมเนียม {{value}}%",

  "deeplePay.paymentChannel.qrCash.title": "Thai QR payment",
  "deeplePay.paymentChannel.qrCash.description": "รองรับแอปพลิเคชันโมบายแบงค์กิ้งทุกธนาคาร",
  "deeplePay.paymentChannel.qrCredit.title": "Visa QR payment",
  "deeplePay.paymentChannel.qrCredit.description": "รองรับเฉพาะแอปพลิเคชัน SCE easy, KMA, CITIBANK และ KTC Mobile",

  "deeplePay.paymentChannel.creditCard.VISA.title": "Visa",
  "deeplePay.paymentChannel.creditCard.MASTERCARD.title": "Mastercard",
  "deeplePay.paymentChannel.creditCard.JCB.title": "JCB",
  "deeplePay.paymentChannel.creditCard.VISA.description": "รองรับบัตรเครดิต Visa ทุกประเภท",
  "deeplePay.paymentChannel.creditCard.MASTERCARD.description": "รองรับบัตรเครดิต mastercard ทุกประเภท",
  "deeplePay.paymentChannel.creditCard.JCB.description": "รองรับบัตรเครดิต JCB ทุกประเภท",

  "deeplePay.paymentChannel.trueMoneyWallet.title": "TrueMoney Wallet",
  "deeplePay.paymentChannel.trueMoneyWallet.description": "ชำระผ่านโทรศัพท์มือถือ",
  "deeplePay.paymentChannel.tab.title.income": "รายได้",
  "deeplePay.paymentChannel.tab.title.withdrawal": "ถอนเงิน",
  "deeplePay.transaction.table.processing": "กำลังดำเนินการ",
  "deeplePay.transaction.table.completed": "สำเร็จ",
  "deeplePay.transaction.table.voided": "ถูกวอยด์",
  "deeplePay.transaction.table.declined": "ไม่สำเร็จ",
  "deeplePay.transaction.table.created": "รอดำเนินการ",
  "deeplePay.transaction.table.withdrawn": "ถอนเงิน",
  "deeplePay.paymentChannel.openGuideline": "ดูรายละเอียดเพิ่มเติมเกี่ยวกับ LeanBiz pay",

  "deeplePay.transaction.table.refund.status.refund_pending": "กำลังดำเนินการ",
  "deeplePay.transaction.table.refund.status.refunded": "คืนเงินสำเร็จ",
  "deeplePay.transaction.table.refund.status.refund_declined": "ไม่สำเร็จ",

  "deeplePay.updatePaymentConfig.error.INCORRECT_MINIMUM_AMOUNT": "จำนวนเงินขั้นต่ำไม่ถูกต้อง",
  "deeplePay.updatePaymentConfig.error.INCORRECT_MOBILE_BANKING_MINIMUM_AMOUNT":
    "จำนวนเงินขั้นต่ำสำหรับโมบายแบงก์กิ้งไม่ถูกต้อง(ขึ้นต่ำ 20 บาท)",
  "deeplePay.radio.description": "ระบบตรวจสอบยอดเงินอัตโนมัติ",
  "deeplePay.details.seeExample": "ดูตัวอย่าง",
  "deeplePay.details.description":
    "LeanBiz pay เป็นวิธีการชำระเงินจาก leanbiz.ai ซึ่งรองรับการชำระเงินจากลูกค้าโดยตรงและเชื่อมต่อกับรายการสั่งซื้ออัตโนมัติ รูปแบบการชำระผ่าน",
  "deeplePay.details.description2": "Thai QR Code payment, Debit / Credit card, Mobile Banking รวมทั้ง E-wallet",
  "deeplePay.details.learnMore": "เรียนรู้เพิ่มเติม",
  "deeplePay.details.info":
    "ทุกรายการธุรกรรมที่ชำระผ่าน LeanBiz pay จะถูกเรียกเก็บภาษีมูลค่าเพิ่ม 7% จากค่าธรรมเนียมแต่ละช่องทางชำระเงิน",
  "bankPayment.radio.title": "โอนเงินผ่านธนาคาร",
  "bankPayment.radio.description": "แอดมินตรวจสอบ ยืนยันการชำระเงิน",
  "bankPayment.details.description":
    "ภาพหลักฐานการโอนเงินเป็นข้อมูลสำคัญที่ทางแอดมินต้องตรวจสอบความถูกต้อง และยืนยันการชำระเงิน ออเดอร์นั้นจะถูกเปลี่ยนสถานะเป็นชำระเงินแล้ว/รอการจัดส่ง ระบบจะส่งใบเสร็จรับเงินให้ลูกค้าอัตโนมัติ",
};

export default { en, th };
